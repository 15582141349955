@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';

.products-section {
  display: flex;
  flex-direction: column;
  gap: spacing.$m;

  &__products {
    background-color: light.$surface-primary-default;
    border-radius: corner-radius.$m;
    padding: spacing.$m;
  }

  &__title {
    margin-bottom: spacing.$m;
  }

  &__product-wrapper {
    display: flex;
    flex-direction: column;
    gap: spacing.$m;
  }
}
